<template>
    <b-modal id="modal-buy" size="lg" hide-footer>
        <div class="allContentModal">
            <div class="spaceText1">
                <p class="text1">{{ dadosCurse.title }}</p>
            </div>
            <div class="animationOpacity2" v-if="src !== null">
                <b-form-group label="" label-for="descricao" class="labelvideo">
                    <video ref="videoPlayer" 
                        class="video-js widthVideoPlayer" 
                        :tech-order="techOrder" 
                        :autoplay="autoplay" 
                        :controls="true"
                        v-on:pause="onVideoPause"
                        @changed="playerStateChanged($event)"
                        >
                        <source :src="src" :type="type">
                    </video>
                </b-form-group>
            </div>
            <div class="spaceText2">
                <span class="text2" v-html="descriptionBuy"></span>
            </div>
            <div>
                <button class="button" @click="toCheckout">{{ textButton }}</button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import 'videojs-youtube';
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
    components: {
    },
    data(){
        return{
            dadosCurse:{},
            autoplay: true,
            player: null,
            techOrder: "youtube",
            type: "video/youtube",
            src: "",
            linkCheckout:'',
            descriptionBuy:'',
            textButton:'Quero Aproveitar Agora'
        }
    },
    methods:{
        toCheckout(){
            window.open(this.linkCheckout);
        },
        playerDispose(){ 
            this.player.dispose(); 
        },
        playerGetTime(){
            return this.player.currentTime();
        },
        getPlayer(){
            this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
                //console.log('onPlayerReady', this);
            });
            //console.log("xxx", this.player);
        },
        onVideoPause: function () {
            //console.log('video is paused')
        },
        play() {
            this.player.play();
        },
        pause() {
            this.player.pause();
        },
        playerStateChanged: function(playerCurrentState) {
            //console.log('player current update state', playerCurrentState);
        },
        getMetaCourse(id){
            let url = id+'/meta?keys[]=sale_link&keys[]=sale_video&keys[]=sale_video_thumb&keys[]=sale_button_txt&keys[]=sale_description&keys[]=sale_video_type&keys[]=tipo_venda';
            serviceCourse
            .read(url)
            .then((resp) => {
                if (resp.tipo_venda === 'tela') {
                    this.$router.push('/curso/venda/'+id);
                } else {
                    this.$root.$emit('bv::show::modal', 'modal-buy', '#btnShow');
                }
                //console.log('meta sale modal', resp);
                this.linkCheckout = resp.sale_link;
                this.src = resp.sale_video;
                if(resp.sale_video_type === 'vimeo'){
                    this.type = "video/vimeo";
                }
                this.descriptionBuy = resp.sale_description;
                if(resp.sale_button_txt !== null){
                    this.textButton = resp.sale_button_txt;
                }
                setTimeout(() => {
                    this.getPlayer();
                }, 1000);
                this.$root.$emit('loadOff');
            })
            .catch((err) => {
                //console.log(err);
                this.$root.$emit('loadOff');
            });
        }
    },
    mounted(){
        this.$root.$on('modal-buy-course', data => {
            //console.log('modal-buy-course', data);
            this.getMetaCourse(data.id);
            this.dadosCurse = data;
        });
    }
}
</script>
<style lang="scss">
    #modal-buy{
    header {
        border: none;
    }
    label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor2);
    }
    .video-js{
        height: 47vh;
    }
    .labelvideo{
        label {
            display: flex !important;
        }   
    }         
    .allContentModal {
        padding: 0px 10px;
        margin: 0 auto;
        display: block;
        text-align: -webkit-center;
    }
    .spaceText2{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .spaceText1{
        margin-bottom: 20px;
    }
    .text1{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        text-align: center;
    }
    .text2{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #818181;
    }
    .button{
        background: #000000;
        width: auto;
        padding: 0px 15px;
        height: 55px;
        border-radius: 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        border: none;
    }

    }
</style>